export function sanitizeUrl(url: string): string {
  // The built-in URL module only works on full URLs (including protocol and host),
  // and we need to work with partial "URLs" like "/.api/sitewide-content"

  const queryIndex = url.indexOf("?");
  const fragmentIndex = url.indexOf("#");
  const queryAndFragmentIndex =
    queryIndex !== -1 ? queryIndex : fragmentIndex !== -1 ? fragmentIndex : url.length;
  const queryAndFragment = url.substring(queryAndFragmentIndex);
  const escapedUrl = url
    .substring(0, queryAndFragmentIndex)
    .replace("://", "__SCHEME_SEPARATOR__")
    .replace(/^\/\//, "__INITIAL_SCHEME_SEPARATOR__");

  const replacedUrl = escapedUrl.replace(/\/{2,}/g, "/");

  const unescapedUrl = replacedUrl
    .replace("__SCHEME_SEPARATOR__", "://")
    .replace("__INITIAL_SCHEME_SEPARATOR__", "//");

  return unescapedUrl + queryAndFragment;
}
