import { GraphQLClient } from "graphql-request";
export type { GraphQLClient } from "graphql-request";
export { request } from "graphql-request";
import { Variables } from "graphql-request/dist/types";
import { RequestInit } from "graphql-request/dist/types.dom";
import { sanitizeUrl } from "./sanitize-url";

/**
 * @deprecated - use prepareClient instead
 */
const ENDPOINT = "./api/sitewide-content/graphql";
/**
 * @deprecated - use prepareClient instead
 */
const client = new GraphQLClient(ENDPOINT);

export const SITEWIDE_CONTENT = "sitewide-content/graphql";

export function prepareClient(...urlParts: string[]): GraphQLClient {
  return new GraphQLClient(sanitizeUrl(urlParts.join("/")));
}

/**
 * @deprecated Use prepareClient to receive a client, then use client.request
 * @see GraphQLClient.request
 */
export const query = <T>(
  query: string,
  variables?: Variables,
  requestHeaders?: RequestInit["headers"]
): Promise<T> => client.request<T>(query, variables, requestHeaders);

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function fetchOptions<T>(url: string, options: T): T {
  return options;
}

export function fetchWithOptions(url: string, options: globalThis.RequestInit): Promise<Response> {
  return fetch(url, options);
}
